export const footerLinksData = [
  {
    path: "/privacy",
    name: "Privacy Policy",
  },

  {
    path: "/menu",
    name: "Menü",
  },


  {
    path: "/iletisim",
    name: "İletişim",
  },

]
